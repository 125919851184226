@import 'bourbon';
@import 'neat';
@import 'variables';
@import 'normalize';
@import 'font-awesome';
@import 'mixins';

@import url(/components/fancybox/source/jquery.fancybox.css);
@import url(/components/jquery.meanmenu/meanmenu.min.css);

@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);

body {
	font-family: "Roboto Condensed";
	font-weight: 300;
	color: $color_text;
	background: $color_bg;
	font-size: $base-font-size;
}

.container {
	@include outer-container;
	padding-left: 1em;
	padding-right: 1em;
	position: relative;
}

img {
	width: 100%;
}

a {
	text-decoration: none;
}

h1,h2,h3,h4,h5 {
	font-weight: 400;
	text-transform: uppercase;
}

h2 {
	font-size: modular-scale(2);
}

h2, h1.page-title {
	&:after {
		display: block;
		content: "";
		padding: 1px;
		width: 50px;
		margin-top: 0.5em;
		background: black;
	}	
}

h3 {
	font-size: modular-scale(1);
	margin-bottom: 0.25em;
	margin-top: 0.25em;
}

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.back {
	color: $color_btn;
	font-weight: 500;
	text-transform: uppercase;

	&:before {
		@include font-awesome('');
		margin-right: 0.5em;

	}	
}

form {
	margin-bottom: 2em;
}

.input, .submit {
	margin: 0.25em 0;
}

label {
	padding: 0.5em 0;
}

input[type=text], input[type=email], input[type=password], input[type=tel], textarea {
	border: 1px solid $color_h;
	padding: 0.5em;

	outline: none;

	transition: all 0.5s;

	&:focus {
		border: 1px solid darken($color_h, 20%);
	}
}

.input.text, .input.email, .input.password, .input.tel, .input.textarea {
	@include row();

	> label {
		@include span-columns(3);
	}

	> input, textarea {
		@include span-columns(9);
	}

	@include media($bp-mobile) {
		> label, input, textarea {
			@include span-columns(8);
		}
	}
}

.recaptcha-item {
	@include row();
	padding-top: 1rem;
	
	.g-recaptcha {
		@include span-columns(9);
		@include shift(3);
	}
}

.input.checkbox {
	@include shift(2);

	> input {
		margin-right: 0.5em;
	}
}

.submit {
	margin: 1em 0;
	
	@include media($bp-mobile) {
		@include shift(0);
	}
}

.form-actions {
	@include row();
	margin: 1em 0;
	@include shift(2);

	.submit {
		margin: 0;
		float: left;
	}
}

.input.forgot-password {
	float: left;

	margin: 0 0 0 1em;
	padding: 0.5em;
}

.button, input[type=submit], button {
	border: 2px solid $color_btn;
	border-radius: 2px;
	background: $color_btn;
	color: white;
	padding: 0.5em 1em;
	transition: all 150ms ease-in;
	cursor: pointer;

	&:hover {
		border-color: lighten($color_btn, 10%);
		background-color: lighten($color_btn, 10%);
	}
}

.button-block {
	display: block;
}

.facet .more-info,
.button-ghost {
	border: 2px solid $color_text;
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	padding: 0.5em 1em;
	transition: background-color 150ms ease-in;

	&:hover {

	}

	&.inverse {
		border-color: white;
		color: white;
		&:hover {
			background-color: white;
			color: $color_text;
		}		
	}
}

header {
	background: white;
	position: relative;
	z-index: 102;

	.logo {
		@include span-columns(1);
		display: inline-block;
		margin-top: 0.75em;

		img {
			width: auto;
		}
	}
	.nav-main {
		@include span-columns(10);
	}
	.main-menu {
		@extend %clear-list;		
		text-align: right;
		> li {
			text-transform: uppercase;
			display: inline;
			position: relative;
			a {
				color: $color_text;
				display: inline-block;
				text-decoration: none;
				padding: 1.5em 1em;
			}
			&.active {
				font-weight: 400;
			}

			&:hover {
				ul {
					display: block;
				}
			}

			ul {
				@include clear-list;
				display: none;
				background: white;
				position: absolute;
				left: 0;
				top: 42px;
				margin: 0;
				padding: 0;
				text-align: left;
				box-shadow: 0 2px 3px rgba(0,0,0,0.1);

				> li a {
					text-align: left;;
					margin: 0;
					padding: .5em 1em;
					min-width: 200px;

					&.outlet {
						color: red;
						font-weight: bold;
					}

					&:before {
						@include font-awesome("");
						margin-right: 10px;
					}

					&:hover {
						background-color: $color-bg;
					}
				}
			}
		}
	}
	.search {
		@include span-columns(1);
		text-align: center;
		padding: 1.5em 0;
		color: $color_text;
		text-decoration: none;

		.searchbtn {
			color: $color_text;
			
			&:before {
				@include font-awesome("");
			}
		}
	}

	@include media($bp-tablet) {
		.logo {
			@include span-columns(1);
		}

		.search {
			@include shift(6);
			@include span-columns(1);
		}
	}
}

.searchbox {
	position: absolute;
	z-index: 101;
	top: -3.5em;
	right: 0;
	background-color: white;
	padding: 1.5em;

	transition: top 0.25s, opacity 0.5s 0.25s;

	&.open {
		top: 3.5em;
	}

	@include row();

	> form {
		margin-bottom: 0;
		width: 600px;
		display: table;
	}

	@include display-context(table) {
		.text {
			@include span-columns(9);

			> input {
				float: none;
				width: 100%;
			}
		}

		.submit {
			@include span-columns(3);
			margin: 0;

			> input, button {
				width: 100%;
			}
		}
	}

	@include media($bp-tablet) {
		width: 100%;

		&.open {
			top: 7em;
		}

		> form {
			width: 100%;
		}
	}

	@include media($bp-mobile-small) {
		.text, .submit {
			display: block;
			width: 100%;
		}
	}
}

footer {
	

	.container {
		@include row();
	}

	.footer-top {
		background: #333333;
		min-height: 100px;

		.block {
			@include span-columns(2);

			h4 {
				color: white;
				margin-bottom: 1em;
			}

			ul {
				@include clear-list();
				margin-bottom: 2em;
			}

			&, a {
				color: darken(white, 40%);
				text-transform: uppercase;
				font-weight: 500;
			}
		}
	}

	.footer-bottom {
		background: black;
		color: white;

		.container {
			padding: 1em 0;
		}

		.copyright {
			@include span-columns(6);
		}

		.starss-logo {
			@include span-columns(6);
			@include omega();

			.starss-logo-wrapper {
				float: right;
			}
		}
	}

	@include media($bp-tablet) {
		.footer-top {
			.block {
				@include span-columns(4);
				@include omega(2n);
			}
		}
	}

	@include media($bp-tablet) {
		.footer-bottom {
			.copyright, .starss-logo {
				@include span-columns(8);
				padding: 0.5em;
				text-align: left;
			}

			.starss-logo {
				.starss-logo-wrapper {
					float: none;
				}
			}
		}
	}

	@include media($bp-mobile-small) {
		.footer-top {
			.block {
				display: none;
			}

			.block-footer-help, .block-footer-contact {
				display: block;
				@include span-columns(8);
			}
		}
	}
}

#carousel {
	margin-bottom: 3em;
	background: #202022;
	//min-height: 500px;
	.slideshow {
		width: 100%;
	}
	.slideshow-controls {
		display: none;
	}
	.banner {
		width: 100%;
		position: relative;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		.info {
			margin: 0em 1em;
			padding: 10em 2em;
			color: white;
			.label {
				text-transform: uppercase;
				&.label-small {
					font-size: 1.5em;
				}
				&.label-large {
					font-size: 5em;
				}
			}
			.more-info {
				margin-top: 1em;
			}
		}
	}

	@include media($bp-tablet) {
		.banner {
			.info {
				padding: 5em 2em;
				font-size: 0.8em;
			}
		}
	}

	@include media($bp-mobile) {
		.banner {
			.info {
				padding: 7em 1em 2em 1em;

				.label {
					display: none;
				}
			}
		}
	}
}


.promoted {
	@include row;
	margin-bottom: 3em;
}

.categories {
	@include row;
	margin-bottom: 3em;
	.category {
		@include span-columns(4);
		position: relative;
		.overlay {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: rgba(0,0,0, 0.3);
			text-align: center;
			transition: background-color 200ms ease-in;

			.label {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				color: white;
				font-weight: 400;
				text-transform: uppercase;
				font-size: 2em;
				border: 2px solid white;
				display: inline-block;
				span {
					display: inline-block;
					padding: 0.5em;
				}

				&:after,
				&:before {
					transition: padding 150ms ease-in;
				}

				&:after {
					content: "";
					border-top: 2px solid white;
					padding-bottom: 10px;				
					display: block;
				}
				&:before {
					content: "";
					border-bottom: 2px solid white;
					display: block;
					padding-top: 10px;
				}
			}			
		}
		&:hover {
			.overlay {
				background-color: rgba(0,0,0, 0.8);
			}
			.label:after {
				padding-bottom: 30px;
			}
			.label:before {
				padding-top: 30px;
			}
		}
	}

	@include media($bp-mobile) {
		display: none;
	}
}

.product-teaser {
	position: relative;
	background-color: white;
	.image {
		overflow: hidden;

		img {
			transition: transform 150ms ease-in;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	.info {
		color: $color_text;
		font-weight: 400;
		padding: 1em;
		background: darken($color_bg, 5%);
		text-transform: uppercase;

		.reference {
			display: block;
			color: darken($color_bg, 30%);
		}
	}
}

.price-wrapper {
	margin-top: 1rem;
	font-style: italic;
	font-size: 0.9rem;

	.old-price,
	.price {
		display: inline;
	}

	.old-price {
		text-decoration: line-through;
		color: red;
		padding-left: .5em;
	}

	.percent-bubble {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 999;
		background: black;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		color: white;
		text-align: center;
		line-height: 50px;
	}		
}

.breadcrumb {
	margin-top: 3em;
	text-transform: uppercase;
	@extend %clear-list;
	li {
		display: inline;
		&:after {
			@include font-awesome("");
			margin-right: 0.5em;
			padding-left: 0.5em;
		}
		&:last-child:after {
			display: none;
		}

		a {
			color: $color_text;
		}
	}
}

.sort-links, .pagination {
	@include clear-list();
	margin: 1em 0 1em 0;

	> li {
		display: inline-block;
		margin: 0 0.1em;

		> a, span {
			color: $color_btn;
			display: inline-block;
			padding: 0.25em 0.5em;
			border-radius: 3px;
		}

		&.active span, &.active a, a:hover {
			background-color: $color_btn;
			color: white;
		}
	}

	.label > span {
		padding-left: 0;

		text-transform: uppercase;
		font-weight: 700;
		color: darken($color_h, 20%);
	}
}

.sort-links {
	> li {
		&.asc, &.desc {
			a:after {
				margin-left: 0.5em;
				font-size: 0.65em;
				vertical-align: middle;
			}
		}
		&.asc {
			a:after {
				@include font-awesome("\f0d7");
			}
		}

		&.desc {
			a:after {
				@include font-awesome("\f0d8");
			}
		}
	}
}

.product-assets {
	.image-large {
		margin-bottom: 1em;
	}

	.images {
		@include row();

		.image {
			@include span-columns(3);
		}
	}
}

dl.attribute-list {
	@include row();

	text-transform: uppercase;
	font-weight: 500;

	&, a  {
		color: darken($color_h, 25%);
	}

	dt, dd {
		padding: 0.15em;
		margin: 0;
	}

	dt {
		width: 40%;
		float: left;
		clear: left;
	}

	dd {
		width: 60%;
		float: right;
	}

	@include media($bp-mobile) {
		dt, dd {
			display: block;
			float: none;
		}

		dd {
			&, a {
				color: darken($color_h, 55%);
			}
		}
	}
}

.size-select {
	@include clear-list();
	margin: 1em 0 ;

	> li {
		display: inline-block;
		margin-bottom: 0.5em;

		> span {
			display: block;
			background-color: rgba($color_btn, 0.40);
			color: white;
			padding: 0.5em;
			margin-right: 0.25em;
		}

		&.in-stock {
			> span {
				background-color: $color_btn;
			}
		}
	}
}

.sub-products {
	margin-bottom: 1em;

	> ul {
		@include clear-list();
		@include row();

		> li {
			@include span-columns(3);
		}
	}

	@include media($bp-tablet) {
		> ul {
			> li {
				@include span-columns(4);
				@include omega(2n);
				margin-bottom: 1em;
			}
		}
	}

	@include media($bp-mobile-small) {
		> ul {
			> li {
				@include span-columns(8);
				@include omega();
			}
		}
	}
}

.block-product-footer {
	margin-top: 2em;
	font-size: 13px;
	color: darken($color_h, 20%);
}

.brand {
	background-color: white;
	.image {
		overflow: hidden;

		img {
			display: block;
			transition: transform 150ms ease-in;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	.info {
		color: $color_text;
		font-weight: 400;
		padding: 1em;
		background: darken($color_bg, 5%);
		text-transform: uppercase;
	}
}


.block-our-store {
	@include row;
	.content,
	.image {
		@include span-columns(6);
	}
	h2 {
		margin-top: 0;
	}

	margin-bottom: 3em;	

	@include media($bp-tablet) {
		.content, .image {
			@include span-columns(8);
			margin-bottom: 1em;
		}
	}
}

.block-home-highlight {

	&, .content {
		border: 1px solid $color_h;
	}
	
	padding: 5px;
	margin-bottom: 3em;

	.image {
		display: none;
	}

	.content {
		padding: 1.5em;
	}

	h2 {
		margin-top: 0;
		font-weight: 200;

		&:after {
			display: none;
		}
	}

	.button {
		float: right;
		margin: -0.5em 0 1em 1em;
	}

	@include media($bp-mobile) {
		.button {
			display: inline-block;
			float: none;
			margin: 0;
		}
	}

}

body.products-index {

	.products {
		@include row;
		//margin-top: 3em;	
		.sidebar {
			@include span-columns(3);

			#facet-toggle {
				@extend .button;
				@extend .button-block;
				display: none;

				text-transform: uppercase;

				&:after {
					@include font-awesome("\f078");
					display: inline-block;
					margin-left: 1em;
					float: right;
				}
			}

			.facet {
				background: white;
				padding: 1em;
				margin-bottom: 2em;

				h3 {
					margin: 0;
					padding: 0;
				}

				ul {
					@extend %clear-list;
					margin: 1em 0;
					li a {
						color: black;
					}
				}

				.more-info {
					font-size: 0.8em;
					padding: 0.3em 0.5em;
					font-weight: 400;
					border-width: 1px;
					color: $color-text;
					&:after {
						@include font-awesome("");
						margin-left: 0.3em;
					}
				}
			}
		}

		.items {
			@include span-columns(9);
			.product-teaser {
				margin-bottom: 2em;
				@include span-columns(4);
				@include omega(3n);
			}
		}
	}

	.top-bar {
		margin: 1.5em 0;
		@include shift(3);

		.paging {
			@include span-columns(8);
		}

		.sort {
			@include span-columns(4);
			text-align: right;
		}
	}

	.bottom-bar {
		@include shift(3);
		margin-bottom: 2em;
	}

	@include media($bp-tablet-landscape) {
		.top-bar {
			@include shift(0);
		}
	}

	@include media($bp-tablet) {

		.products {
			.sidebar, .items {
				@include span-columns(8);
			}

			.sidebar {
				margin-bottom: 1em;
				#facets-wrapper {
					display: none;
				}

				#facet-toggle {
					display: block;
				}

				&.open {
					#facet-toggle {
						&:after {
							content: "\f077";
						}
					}

					#facets-wrapper {
						display: block;
					}
				}
			}
		}

		.top-bar {
			.paging {
				@include span-columns(8);

				.pagination {
					margin-bottom: 0.25em;
				}
			}

			.sort {
				@include span-columns(8);
				text-align: left;

				.sort-links {
					margin-top: 0.25em;
				}
			}
		}

		
		.bottom-bar {
			@include shift(0);
		}
	}

	@include media($bp-mobile) {
		.products {
			.items {
				.product-teaser {
					@include span-columns(8);
				}
			}
		}
	}

}

body.products-view {
	
	.top-action {
		float: right;
		margin-top: -4em;
	}

	.product-detail {
		position: relative;
	}

	article.product {
		@include row();

		.product-detail-left {
			@include span-columns(7);
		}

		.product-detail-right {
			@include span-columns(5);
		}

		.product-detail {
			background-color: white;
			padding: 1.5em;

			.product-title {
				margin-top: 0;
				font-size: 1.40em;
			}
		}
	}

	@include media($bp-tablet) {
		article.product {
			.product-detail-left, .product-detail-right {
				@include span-columns(8);
				margin-bottom: 1em;
			}
		}
	}

	@include media($bp-mobile) {
		.top-action {
			float: none;
			margin-top: 0;
			margin-bottom: 1em;
		}
	}
}

body.pages-brands {
	.brands {
		@include row();
	}

	.brand {
		@include span-columns(3 of 24);
		@include omega(8n);
		margin-bottom: 1.5em;
	}

	@include media($bp-tablet) {
		.brand {
			@include omega-reset(8n);
			@include span-columns(6 of 24);
			@include omega(4n);
		}
	}

	@include media($bp-mobile) {
		.brand {
			@include omega-reset(4n);
			@include span-columns(4);
			@include omega(2n);
		}
	}

	@include media($bp-mobile-small) {
		.brand {
			@include span-columns(8);
			@include omega();
		}
	}
}

body.pages-contact {
	.contact-row {
		@include row();

		.contact-left {
			@include span-columns(6);
		}

		.contact-right  {
			@include span-columns(6);
			padding: 1.5em;
			background-color: white;
		}

		@include media($bp-tablet) {
			.contact-left, .contact-right {
				@include span-columns(8);
			}
		}
	}

	.block-contact-info {
		margin-top: 2em;
	}
}

.node {
	margin-bottom: 1em;

	&.node-with-sidebar {
		@include row();

		.body  {
			@include span-columns(7);
		}

		.sidebar {
			@include span-columns(5);
		}

		.images {
			@include row();

			.image {
				@include span-columns(3);
				margin-bottom: 0.5em;

				&:first-child {
					@include span-columns(12);
				}
			}
		}
	}

	@include media($bp-tablet) {
		&.node-with-sidebar {
			.body, .sidebar {
				@include span-columns(8);
				margin-bottom: 1em;
			}
		}
	}

	@include media($bp-mobile-small) {
		&.node-with-sidebar {
			.images {
				.image {
					@include span-columns(8);
				}
			}
		}
	}
}


#flashMessage h3{
     margin: 0 0 5px 0;
}

#flashMessage p{
     margin: 0;
}

@keyframes animate-bg {
    from {
        background-position: 0 0;
    }
    to {
       background-position: -80px 0;
    }
}

#flashMessage{
    background-size: 40px 40px;
    background-color: $color_btn;
     box-shadow: inset 0 -1px 0 rgba(255,255,255,.4);
     width: 100%;
     border: 1px solid;
     color: #fff;
     padding: 15px;
     display: none;
     position: relative;
     z-index: 999999999999;
     text-shadow: 0 1px 0 rgba(0,0,0,.5);
     text-align: center;

     .message-close {
        position: absolute;
        right: 1em;
        top: 50%;
        margin-top: -0.75em;
        color: white;
        text-shadow: 0 1px 0 rgba(0,0,0,.5);
        font-size: 2em;
        cursor: pointer;
     }
}

#noticeMessage, #authMessage {
    @extend #flashMessage;
}

.mean-container {
	.mean-push {
		display: none;
	}

	.mean-bar, .mean-nav {
		background-color: $color-btn;
	}
}
