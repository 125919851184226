%clear-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin clear-list() {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

@mixin font-awesome($char) {
  content: $char;
  font-family: "FontAwesome";
}