// TYPO
$base-font-size: 16px;

// COLORS
$color_btn: #213549;
$color_text: #442011;
$color_bg: #f2f1ef;
$color_h: #e1e1e1;

// BREAKPOINTS
$bp-site-width: new-breakpoint(max-width $max-width 12);
$bp-tablet-landscape: new-breakpoint(max-width 1024px 12);
$bp-tablet: new-breakpoint(max-width 768px 8);
$bp-mobile: new-breakpoint(max-width 480px 8);
$bp-mobile-small: new-breakpoint(max-width 360px 8);

//OTHERS
$fa-font-path: "/components/font-awesome/fonts";